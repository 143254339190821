<template>
    <div>
        <div>
            <div class="d-flex  justify-content-center align-items-center">
                <b-col md="8" class="mx-auto app-login-box">

                    <div class="modal-dialog w-100 mx-auto">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="h5 modal-title text-center">
                                    <h4 class="mt-2">
                                        <span>Пожалуйста, авторизуйтесь.</span>
                                    </h4>
                                </div>
                            </div>
                            <div class="modal-footer clearfix">
                                <div class="float-right">
                                    <b-button variant="primary" size="lg" v-on:click="Login">Авторизоваться</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { PublicClientApplication }  from "@azure/msal-browser";

    export default {
        data() {
            return {
                password: "",
                message: "",
                isMessage: false
            }
        },

        computed: {
            ...mapGetters({
                isRegisteredAdmin: "isRegisteredAdmin",
                email: "thatAdminEmail",
                username:"thatAdminUsername",

            })
        },

        methods: {
            LoginBackend() {
                this.isMessage = false;
                if (!this.password) {
                    this.message = "Введите, пожалуйста, пароль";
                    this.isMessage = true;
                } else if (this.password.length < 6) {
                    this.message = "Пароль должен содержать не менее 6 символов";
                    this.isMessage = true;
                } else {
                    const credentials = {
                        email: this.email,
                        username: this.username,
                        password: this.password
                    }
                    if (!this.isRegisteredAdmin) {
                        this.message = "Вначале нужно зарегистрироваться";
                        this.isMessage = true;
                    } else {
                        this.$store.dispatch('login', credentials)
                        .then(() => this.$router.push('/adminvacation'));
                    }
                    this.message = "Вы успешно вошли в систему";
                    this.isMessage = true;
                }
            },
            async Login() {
                // await this.$msalInstance
                //     .loginPopup({})
                //     .then(() => {
                //         const myAccounts = this.$msalInstance.getAllAccounts();
                //         this.account = myAccounts[0];
                //         this.$emitter.emit('login', this.account);
                //     })
                //     .then(()=>{
                //         this.$router.push({name: 'vacations'});
                //     })
                //     .catch(error => {
                //         console.error(`error during authentication: ${error}`);
                //     });
                console.log(document.getElementById('signInButton'));
                document.getElementById('signInButton').click();
            },
        },
        async created() {
            this.$msalInstance = new PublicClientApplication(this.$store.state.msalConfig);
        },

        async mounted() {
            const accounts = this.$msalInstance.getAllAccounts();
            if (accounts.length == 0) {
                return;
            }
            this.account = accounts[0];
            //this.$emitter.emit('login', this.account);
            if(this.account){
                this.$router.push({name:'vacations'})
            }
        },
    }
</script>